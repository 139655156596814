$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
@use "../poloVariables" as polo;

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

$office-card-min-height: 270px;

// Custom containers to restrict widths.
.map-container, .list-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.map-wrapper {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;

  .map-container {
    max-width: 100vw;

    .map {
      height: 600px;
    }

    .marker-dcs-small {
      background-image: url('../../markers/cluster.svg');
      background-size: cover;
      cursor: pointer;

      &.office {
        width: 30px;
        height: 30px;
        border: 3px solid polo.$primary;
        border-radius: 50%;
        display: none;
        z-index: 10;
        margin-top: -20px;

        &:before {
          content: ' ';
          position: absolute;
          top: 26px;
          left: 7px;
          width: 10px;
          height: 10px;
          border-top: 5px solid polo.$primary;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          z-index: 1;
        }
      }

      &.globe {
        width: 56px;
        height: 30px;
        background: polo.$primary;
        border-radius: 15px;
        font-size: 15px;
        text-align: right;
        padding: 7px;
        line-height: 1;
        color: polo.$white;
        font-weight: 600;

        &:after {
          position: absolute;
          left: 3px;
          top: 3px;
          content: ' ';
          overflow: hidden;
          height: 24px;
          width: 24px;
          border-radius: 12px;
          background-size: contain;
          background-position: center left;
          background-repeat: no-repeat;
        }
      }
    }

    .marker-dcs-popup {
      max-width: 200px;
    }

    .marker-dcs-popup-content {
      text-align: center;
      font-family: 'Open Sans', sans-serif;
    }

    .network-map-overlay {
      position: absolute;
      top: 30px;
      left: 30px;
      //display: none; // Displayed by JavaScript.
      z-index: 100001;
      background: white;
      width: 200px;
      max-width: 360px;
      padding: polo.$card-spacer-y polo.$card-spacer-x;
      border: polo.$card-border-width solid polo.$card-border-color;
      border-radius: 10px;
      box-shadow: polo.$card-box-shadow;
      font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;

      .ac-title {
        padding-left: 0;
      }

      &.office {
        left: unset;
        right: 30px;
        max-height: 375px;
        overflow-y: scroll;
        width: 300px;
        display: none;

        .network-map-overlay-inner {
          position: relative;
        }
      }

      .office-card-image-wrapper {
        display: none;
      }

      .office-card-image-box {
        display: block;
        height: 125px;
        width: 100%;
        background-size: cover;
        margin-bottom: 16px;
        background-position: center;
      }

      .office-card-info {
        margin-bottom: 40px;
      }

      .office-city {
        font-family: Barlow, sans-serif;
        font-size: 2em;
        color: #000;
        font-weight: 700;
        margin-bottom: 12px
      }

      .office-name {
        font-family: Barlow, sans-serif;
        font-size: 1.333em;
        font-weight: 500;
        text-transform: uppercase;
        color: #000;
        margin-bottom: 12px
      }

      .office-address, .office-contact {
        position: relative;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #9EA4B5;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.005rem;
        font-family: Barlow, sans-serif;

        img {
          max-height: 16px;
          position: absolute;
          top: 2px;
        }

        span, a {
          color: #9EA4B5;
          padding-left: 25px;
        }
      }

      .office-btn {
        text-align: right;
        width: 100%;
        bottom: unset;
        right: unset;
        margin-bottom: 10px;

        .btn {
          position: absolute;
          margin-top: 5px;
          font-size: 12px;
          bottom: -15px;
          right: 0;
        }
      }

      .network-map-overlay-inner {
        background-color: #fff;
        border-radius: 3px;
        padding: 5px 0;
        margin-bottom: 10px;

        *[data-filter] {
          cursor: pointer;

          &:hover {
            color: polo.$primary;
          }
        }

        .country-row {
          font-size: 12px;

          .flag-img {
            display: inline-block;
            max-height: 14px;
            max-width: 14px;
            border-radius: 50%;
            margin-right: 3px;
          }

          .number-office {
            float: right;
          }
        }
      }
    }
  }
}

#map-offices {
  div[data-show="continent"] {
    .row {
      width: 100%;
      display: block;

      .col {
        width: 100%;
      }
    }

    h2 {
      font-size: 2em;
    }

    p {
      font-size: 1.15em;
    }

    img {
      display: none;
    }
  }
}

.list-wrapper {
  padding: 4.57rem 0;

  @include media-breakpoint-down(md) {
    .tab-content {
      h2 {
        margin-bottom: 0;
      }

      > .tab-pane {
        display: block;
        opacity: 1;
      }
    }
  }

  .continent-tabs {
    background-image: url("../../images/mappa.png");
    background-size: contain;

    > .nav-tabs {
      justify-content: center;
    }

    .country-wrapper {
      margin-top: $grid-gutter-width;

      &:not(:last-child) {
        border-bottom: 3px rgba(181, 187, 202, 0.50) solid;
      }

      .office-card {
        min-height: $office-card-min-height;
      }
    }
  }

  // Removing background and border for the accordion.
  .accordion .accordion-item {
    background-color: transparent;
    border: none;
  }
}

.office-card-body {
  display: flex;
  // The percentages in here must add to 100.
  gap: 4%;

  .office-card-info {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 64%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .office-city {
      color: polo.$black;
      font-size: 2.286rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.011rem;
    }

    .office-name {
      color: polo.$black;
      font-size: 1.428rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.007rem;
      text-transform: uppercase;
    }

    .office-address, .office-contact {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;
      color: polo.$gray;
      font-size: 1.142rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.005rem;

      img {
        max-height: 16px;
        position: absolute;
        top: 2px;
      }

      span, a {
        color: #9EA4B5;
        padding-left: 25px;
      }
    }

    .office-btn {
      margin-top: auto;
      align-self: flex-end;

      .btn {
        padding: 10px;
        margin-bottom: 0;
      }
    }
  }

  .office-card-image-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32%;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;

    .office-card-info, .office-card-image-wrapper {
      flex-basis: 100%;
    }

    .office-card-info {
      .office-city {
        padding-top: 5px;
        font-size: 1.854rem; //26px
      }
    }

    .office-card-image-wrapper {
      height: 140px;
      background-size: cover;
      background-position: center;
    }

  }
}
