$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
@use "../poloVariables" as polo;
@use "colors" as colors;

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.map-wrapper {
  margin-top: 50px;
}

.counters {
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 40px;
  padding-bottom: 40px;

  .icon {

    img {
      width: 50px;
    }
  }

  .counter {
    color: white;

    span {
      font-family: Barlow, sans-serif;
      font-weight: 800;
      font-size: 50px;
      line-height: 54px;
    }
  }

  p {
    color: white;
    font-family: Barlow, sans-serif;
    font-weight: 500;
    font-size: 20px;
  }

  @media (min-width: 992px) {
    p {
      font-size: 24px;
      padding-left: 20px;
      padding-right: 20px;
    }
    span {
      font-size: 70px !important;
      line-height: 84px;
    }
  }
}

.continent-name {
  font-family: polo.$font-family-base;
  font-weight: 400;
  font-size: medium;
  padding-bottom: 0;
  margin-bottom: 20px;
  border-bottom: 1px #CACACB solid;
  margin-top: 40px;
  color: colors.$dcs_darkblue_header;
}

.country-name {
  padding-bottom: 10px;
  color: polo.$primary;
  font-weight: 500;
  font-size: 2.57rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.013rem;
  margin-bottom: 10px;

  .flag-img {
    width: 35px;
    min-width: 35px;
    height: 35px;
    vertical-align: bottom;
    border-radius: 50%;
  }

  .number-office {
    font-size: 1.428rem;
    font-family: polo.$font-family-base;
    color: polo.$primary;
    text-transform: uppercase;
    float: right;
    padding-top: 8px;

    .offices {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 2px solid polo.$primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-left: 10px;
      font-size: 1.428rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.008rem;
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-down(xs) {
    padding-top: 10px;
    font-size: 1.714rem;
    margin-bottom: 0;

    .flag-img {
      width: 25px;
      min-width: 25px;
      height: 25px;
    }

    .number-office {
      padding-top: 5px;
      font-size: 0.857rem;

      .offices {
        font-size: 0.857rem;
        width: 25px;
        height: 25px;
      }
    }
  }
  @media (max-width: 330px) {
    .number-office {
      font-size: 0;
    }
  }
}
