@use "../../vendor/oi-layout/polo/assets/scss/variables" as v;

// REDEFINE VARIABLES

$font-family-base: "Barlow", sans-serif;

$primary: #002E5F;
$secondary: #7B92D2;
$success: #74AF27;
$gray: #9EA4B5;
$light-gray: #CED1DA;
$black: #000;
$white: #FFF;

$theme: $primary;
$body-color: $gray;

$color-theme: #5469A4; // #0127ff
$form-label-color: $body-color;
$border-color: $light-gray;
$font-size-base: 1.428rem;

$card-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
$card-spacer-x: 24px;
$card-spacer-y: 24px;
$card-border-width: 2px;
$card-border-color: $light-gray;
$card-border-radius: 5px;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1222px
);

// Button padding and radius:
$btn-padding-x: 0.715rem;
$btn-padding-y: 0.715rem;
$btn-border-radius: 0;

// I have to do this as well because Polo defines paddings with no variables.
button.btn,
.btn:not(.close):not(.mfp-close),
a.btn:not([href]):not([tabindex]) {
  padding: $btn-padding-x $btn-padding-y;
}

.heading-text.heading-section > h2:before {
  background-color: $color-theme;
}

// Overriding this because for some reason the theme sets the font explicitly.
.sidebar-menu {
  font-family: $font-family-base;
}

$background-light: #002E5F;
$footer-bg: #002E5F;

$header-height: 157px;
$header-responsive-height: 100px; // Project specific.
$header-sticky-height: 120px; // Project specific.

$theme-colors: map-merge(
                v.$theme-colors,
                (
                        "primary": $primary,
                        "secondary": $secondary,
                        "success": $success,
                )
);

$gray-dcs: #E9EEF9;